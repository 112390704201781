@import "@uxu/design-system/style/mixins";

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.section {
  @extend .flexCenter;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  background-repeat: no-repeat;
  background-position-x: 400vw;

  @include media(s) {
    background-size: 50%;
    background-position: bottom right;
  }

  @include media(m) {
    background-size: auto;
  }
}

.wrapperButtons {
  @extend .flexCenter;
  flex-wrap: wrap;
  gap: var(--uxu-space-large);

  @include media(s) {
   padding-top: var(--uxu-space-large);
  }
}

.headerWithButtonContainer {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 6rem);
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include media(s) {
    width: 50%;
    align-items: flex-start;
  }

  h1 {
    text-align: center;
    font-size: var(--uxu-font-size-h3);

    @include media(s) {
      text-align: left;
      font-size: calc(var(--uxu-font-size-h4) * 2);
    }
  }

  p, span {
    width: 100%;
    text-align: center;


    @include media(s) {
      text-align: left;
      font-size: var(--uxu-font-size-h3);
    }
  }
}

.listLinks {
  display: none;

  @include media(s) {
    left: 0;
    bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    list-style: none;
    gap: calc(var(--uxu-space-large) * 2);

    li {
      position: relative;

      &:last-of-type::after {
        display: none;
      }

      &::after {
        content: "";
        height: 0.2rem;
        width: 3rem;
        right: -4.5rem;
        top: 2rem;
        border-radius: 100%;
        display: block;
        position: absolute;
        background: var(--uxu-color-gray-5);
      }
    }

    a {
      font-weight: bold;
      color: var(--uxu-color-link-secound);
      font-size: var(--uxu-font-size-h3);

      &:hover {
        color: var(--uxu-color-link-hover);
      }
    }
  }
}