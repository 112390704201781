@import "../../../style/mixins";

.textScrambleSign {
  overflow: hidden;
  position: relative;
  display: block;
  height: 8rem;
  color: var(--uxu-color-text-header);
  font-weight: var(--uxu-font-weight-bold);
  font-size: var(--uxu-font-size-h4);

  @include media(s) {
    font-size: calc(var(--uxu-font-size-h4) * 2);
  }

  span {
    font-size: var(--uxu-font-size-h4) !important;
    color: var(--uxu-color-text-default);

    @include media(s) {
      font-size: calc(var(--uxu-font-size-h4) * 2) !important;
    }
  }
}