@import "@uxu/design-system/style/mixins";

$card-height: 15rem;
$menu-width: 25rem;

.section {
  width: 100%;
  position: relative;
  padding: var(--uxu-space-default) 0;

  @include media(s) {
    padding: calc(var(--uxu-space-large) * 4) 0;
  }
}


.row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--uxu-space-default);
}

.col {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--uxu-space-default);

  @include media(s) {
    width: max-content;
  }
}

.menu {
  position: sticky;
  top: 7.9rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-small);
  padding: var(--uxu-space-large) var(--uxu-space-default);
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  background: var(--uxu-color-background-secound);
  height: max-content;

  @include media(s) {
    width: $menu-width;
  }

  h4 {
    padding-bottom: var(--uxu-space-default);
  }

  button {
    background: transparent;
    text-align: left;
    font-weight: bold;
    color: var(--uxu-color-link-default);
    cursor: pointer;

    &.active,
    &:hover {
      color: var(--uxu-color-link-hover);
    }
  }
}

.content {
  width: 100%;
  perspective: 100rem;

  @include media(s) {
    width: calc(100% - ($menu-width + var(--uxu-space-default)));
  }
}

.card {
  flex: 1 1 100%;
  height: $card-height;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;

  @include media(s) {
    flex: 1 1 calc(50% - var(--uxu-space-default));
  }

  @include media(m) {
    flex: 1 1 calc(33.333% - var(--uxu-space-default));
  }

  @include media(l) {
    flex: 1 1 calc(25% - var(--uxu-space-default));
  }

  .face {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--uxu-space-default);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $card-height;
    padding: var(--uxu-space-default);
    border-radius: 0.8rem;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    backface-visibility: hidden;
    border: var(--uxu-border-default);

    p {
      font-size: var(--uxu-font-size-small);
    }

    svg {
      fill: var(--uxu-color-link-secound);
      max-width: 80%;
      max-height: 3.5rem;
      display: block;
    }
  }

  .faceFront {
    height: 15rem;
    border: var(--uxu-border-default);
  }

  .faceBack {
    font-size: 1.5rem;
    transform: rotateY(180deg);
  }

  &:hover {
    .faceFront {
      transform: rotateY(-180deg);
    }

    .faceBack {
      transform: rotateY(0deg);
    }
  }
}