@import "@uxu/design-system/style/mixins";

$card-height: 15rem;
$menu-width: 25rem;

.section {
  width: 100%;
  position: relative;
  padding: var(--uxu-space-default) 0;

  @include media(s) {
    padding: calc(var(--uxu-space-large) * 4) 0;
  }
}


.row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--uxu-space-default);
}

.col {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--uxu-space-default);

  @include media(s) {
    width: max-content;
  }
}

.menu {
  position: sticky;
  top: 7.9rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-small);
  padding: var(--uxu-space-large) var(--uxu-space-default);
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  background: var(--uxu-color-background-secound);
  height: max-content;

  @include media(s) {
    width: $menu-width;
  }

  h4 {
    padding-bottom: var(--uxu-space-default);
  }

  button {
    background: transparent;
    text-align: left;
    font-weight: bold;
    color: var(--uxu-color-link-default);
    cursor: pointer;

    &.active,
    &:hover {
      color: var(--uxu-color-link-hover);
    }
  }
}

.content {
  width: 100%;
  perspective: 100rem;

  @include media(s) {
    width: calc(100% - ($menu-width + var(--uxu-space-default)));
  }
}

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 30rem;
  gap: var(--uxu-space-large);
  padding: var(--uxu-space-large) var(--uxu-space-default);
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);

  @include media(s) {
    flex-direction: row;
    gap: calc(var(--uxu-space-large) * 2);
    padding: var(--uxu-space-default) calc(var(--uxu-space-large) * 2);
  }


  svg {
    width: 20rem;
    max-height: 5rem;
    fill: var(--uxu-color-text-header);
  }

 h4{
   padding-bottom: var(--uxu-space-default);
 }
  p{
    font-size: var(--uxu-font-size-small);
  }
}